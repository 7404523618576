:root {
  --color-icon: #FFFFF;  
  --social-icon1: #000000; 
  --social-icon2: #0077B5;  
  --social-icon3: #3b5999;
  --social-icon4: #1DA1F2;
  --social-icon5: #e4405f;
}

.icon-list {
  width: 100%;
  max-width: 50rem;
  min-width: 20rem;
  padding: 0 1.5rem;
  display: flex;
  justify-content: space-between;
}

.icon-item {
  list-style: none
}

.icon-link {  
  display: inline-flex;
  font-size: 3rem;  
  text-decoration: none;  
  color: var(--color-icon);
  width: 6rem;
  height: 6rem;   
  transition: .5s linear;
  position: relative;
  z-index: 1;
  margin: auto
}

.icon-link:hover {
  color: #fff;
}

.icon-link i {
  margin: auto;    
}

.icon-link::before {  
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 6rem;
  height: 6rem;
  background: #000;
  border-radius: 50%;
  z-index: -1;
  transform: scale(0);
  transition: 0.3s cubic-bezier(.95, .32, .37, 1.21);
}
  
.icon-link:hover::before {  
  transform: scale(1);
}

.icon-item:nth-child(1) a:hover:before { 
  background: var(--social-icon1); 
}

.icon-item:nth-child(2) a:hover:before { 
  background: var(--social-icon2); 
}

.icon-item:nth-child(3) a:hover:before { 
  background: var(--social-icon3); 
}

.icon-item:nth-child(4) a:hover:before { 
  background: var(--social-icon4); 
}

.icon-item:nth-child(5) a:hover:before { 
  background: var(--social-icon5); 
}