@font-face {
  font-family: 'CorradineHandwriting';
  src: url('./CorradineHandwriting.otf') format('opentype');
}

.App {
  text-align: center;
  font-family: 'CorradineHandwriting';
}

.App-logo {
  pointer-events: none;
  margin-bottom: 20px;
}

.title {
  margin-bottom: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 20s linear; */
    border-radius: 50%;
    width: calc(25vmin);
    height: calc(25vmin);

    min-width: 200px;
    min-height: 200px;
  }
}

.App-header {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 10s ease infinite;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}